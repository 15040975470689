
import Vue, { PropType } from "vue";
import { DialogCloseType, DialogI } from "@/types/dialog";
import BaseDialog from "@/components/dialog/BaseDialog.vue";

export default Vue.extend({
  name: "MediaAddFileDialog",
  components: {
    BaseDialog,
  },
  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },
  data: () => ({
    selectedFile: null as File | null,
  }),
  methods: {
    submitForm() {
      this.dialog.close({
        type: DialogCloseType.SUBMITTED,
        payload: {
          file: this.selectedFile,
        },
      });
    },
  },
});
